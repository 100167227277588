import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const CardLink = ({ to, svg, title }) => {
  return (
    <Link to={to} className="flex self-stretch w-full">
      <div
        className={`w-full shadow rounded text-center p-5 transition-colors duration-300 self-stretch border border-transparent hover:border-blue-500 group`}
      >
        <div className="mb-3 group-hover:text-blue-500">{svg}</div>
        <div className={`transition-colors duration-300 group-hover:text-blue-500`}>
          {title}
        </div>
      </div>
    </Link>
  )
}

CardLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  svg: PropTypes.node.isRequired,
}

export default CardLink
