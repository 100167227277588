import React from "react"
import { Link } from "gatsby"
import Layout from "./../components/layouts/Default"
import CardLink from "./../components/common/CardLink"
import RequestLeftCol from "./../components/layouts/partials/pages/request/RequestLeftCol"

export default function Page({ data, location }) {
  const svg = {
    contact: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
        <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
      </svg>
    ),
    shield: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"></path>
      </svg>
    ),
    finance: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="7" y="9" width="14" height="10" rx="2" />
        <circle cx="14" cy="14" r="2" />
        <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
      </svg>
    ),
    invest: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />
        <polyline points="4 12 7 8 11 10 16 4 20 2" />
      </svg>
    ),
  }

  return (
    <Layout
      title="Anfrage stellen bei Dierda Finanzdienstleistungen"
      description="Wir bieten Versicherungen, Finanzierungen und Hilfe bei Deiner Geldanlage an."
      location={location}
      smallFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <div className="flex flex-wrap">
              <div className="w-full mb-5 lg:hidden">
                <Link to="/" className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#1939ec"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="8" y1="12" x2="16" y2="12" />
                    <line x1="8" y1="12" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="12" y2="8" />
                  </svg>{" "}
                  Zurück zur letzten Seite
                </Link>
              </div>
              <div className="w-full mb-5">
                <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
                  Wie können wir Dir helfen?
                </h1>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="flex w-full p-3 lg:w-1/3">
                <CardLink
                  to="/anfragen/kontakt/"
                  svg={svg.contact}
                  title="Allgemeiner Kontakt"
                />
              </div>
              <div className="flex w-full p-3 lg:w-1/3">
                <CardLink
                  to="/versicherungen/berufsunfaehigkeitsversicherung/anfragen/"
                  svg={svg.shield}
                  title="Berufs&shy;unfähigkeits&shy;versicherung"
                />
              </div>
              <div className="flex w-full p-3 lg:w-1/3">
                <CardLink
                  to="/versicherungen/altersvorsorge/anfragen/"
                  svg={svg.shield}
                  title="Altersvorsorge"
                />
              </div>
              <div className="flex w-full p-3 lg:w-1/3">
                <CardLink
                  to="/finanzierungen/baufinanzierung/anfragen/"
                  svg={svg.finance}
                  title="Baufinanzierung"
                />
              </div>
              <div className="flex w-full p-3 lg:w-1/3">
                <CardLink
                  to="/finanzierungen/privatkredit/anfragen/"
                  svg={svg.finance}
                  title="Privatkredit"
                />
              </div>
              <div className="flex w-full p-3 lg:w-1/3">
                <CardLink
                  to="/geldanlage/allgemeine-geldanlage/anfragen/"
                  svg={svg.invest}
                  title="Allgemeine Geldanlage"
                />
              </div>
            </div>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
